<template>
<div @click="gotoWp" class="wp-container" v-bind:class="{archived: workPackage.isDeleted}">
  <div class="d-flex flex-column justify-space-between pa-2">
    <div><span class="font-weight-bold" v-if="workPackage.isDeleted">[ARCHIVED]</span> {{ workPackage.title }}</div>
    <div>{{ members }}</div>
  </div>

  <div class="wp-meta">
    <div class="wp-meta-details">
      <table style="width: 100%">
        <tbody>
        <tr v-for="item of metaItems">
          <td>{{ item.name }}</td>
          <td style="text-align: right">{{ item.value }}</td>
        </tr>
        </tbody>
      </table>


    </div>
    <div class="wp-meta-ring">
      <vc-donut :size="80" background="#ECEFF1" :sections="statusSections" :thickness="30" unit="px" />
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import {getHardColor} from "@/utils/status";

export default defineComponent({
  props: {
    workPackage: {
      type: Object
    },
    canModify: Boolean
  },
  computed: {
    metaItems() {
      return [
        {name: 'Deliverables', value: this.workPackage.issues.filter(i => i.type === 'deliverable' && !i.isDeleted).length },
        {name: 'Milestones', value: this.workPackage.issues.filter(i => i.type === 'milestone' && !i.isDeleted).length },
        {name: 'Inputs', value: this.workPackage.relations.filter(r => !r.isDeleted).length }
      ]
    },
    members() {
      return this.workPackage.team.memberships
        .map(m => m.user.firstname + ' ' + m.user.lastname)
        .join(', ');
    },
    statusSections() {
      let pie = [];
      let counter = 0;
      for (const [key, value] of Object.entries(this.workPackage.statusList)) {
        counter += value;
      }

      for (const [key, value] of Object.entries(this.workPackage.statusList)) {
        pie.push({value: value / counter * 100, color: getHardColor(key)});
      }

      if (counter === 0) {
        pie.push({value: 100, color: '#F5F5F5'})
      }

      return pie;
    },
  },
  methods: {
    gotoWp() {
      this.$router.push({name: 'wp-redirect', params: {wpId: this.workPackage._id}})
    }
  }
});
</script>

<style scoped>
.wp-container {
  display: flex;
  justify-items: start;
  justify-content: space-between;
  height: 100px;
  background-color: #ECEFF1;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.wp-meta {
  display: flex;
}

.wp-meta-details {
  display: flex;
  background-color: #CFD8DC;
  border-radius: 5px;
  min-width: 160px;
  padding-left: 6px;
  padding-right: 6px;

}

.wp-meta-ring {
  display: flex;
  padding-left: 8px;

}
</style>
