<template>
  <div v-if="filteredWorkPackages.length >= 1 || !showArchived" class="pb-3">
    <div class="d-flex justify-space-between pb-3">
      <div class="text-h5 text-nowrap text-truncate">
        {{ showArchived ? 'Archived Work Packages' : 'Work Package Overview'}}
      </div>
      <div>
        <v-btn v-if="!showArchived && canCreate" outlined @click="openCreateModal">Create new Work Package</v-btn>
      </div>
    </div>
    <div>
      <work-package-list-item
        v-for="workPackage of filteredWorkPackages" v-bind:key="workPackage._id"
        :work-package="workPackage"
        :can-modify="canCreate"
      />
    </div>
  </div>
</template>

<script>
import Intranet from '@/core/intranet';
import { defineComponent } from 'vue'
import WorkPackageListItem from "@/modules/project-management/components/work-package/WorkPackageListItem.vue";
import {mapGetters} from "vuex";

export default defineComponent({
  components: {
    WorkPackageListItem
  },
  props: {
    workPackages: {
      required: true,
      type: Array
    },
    showArchived: Boolean
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('unit', {
      activeUnit: 'active'
    }),
    canCreate() {
      return this.user.memberships.filter(m => m.unit === this.activeUnit.id).length >= 1;
    },
    filteredWorkPackages() {
      return this.workPackages.filter(w => this.showArchived ? w.isDeleted : !w.isDeleted);
    },
  },
  methods: {
    openCreateModal() {
      Intranet.modals().openModal('work-package-change-modal', {
        action: 'create'
      })
    },
  }
});
</script>

<style scoped>
.archived {
  opacity: 0.6;
}
</style>
