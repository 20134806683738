<template>
  <div class="pa-5">
    <div v-if="!$apollo.loading">
      <work-package-list :show-archived="false" :work-packages="workPackages" />
      <work-package-list :show-archived="true" :work-packages="workPackages" />
    </div>
    <div v-else>
      <v-skeleton-loader
        type="list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </div>
  </div>

</template>

<script>
import WorkPackageListQuery from "@/modules/project-management/graphql/WorkPackageList.graphql";
import _ from "lodash";
import {mapGetters} from "vuex";
import WorkPackageList from "@/modules/project-management/components/work-package/WorkPackageList";

export default {
  name: "WorkPackageListPage",
  components: {WorkPackageList},
  apollo: {
    workPackages: {
      query: WorkPackageListQuery,
      update: data => _.flattenDeep(data.unit.teams.map(t => t.workPackages)),
      variables() {
        return {
          id: this.unit.id
        }
      },
    }
  },
  computed: {
    ...mapGetters('unit', {
      unit: 'active'
    }),
  }
}
</script>

<style scoped>

</style>
